// src/components/OverlayButton.js
import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    borderRadius: '10px',
    padding: theme.spacing(2, 4, 3),
  },
}));

const OverlayButton = ({ buttonText, overlayTitle, overlayContent, icon, iwidth, iheight }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Button 
        variant="contained" color="primary" onClick={handleOpen} 
        style={{ width: "100%", margin: "10px" }}
        startIcon={icon ? <img src={icon} alt="icon" style={{ width: iwidth, height: iheight, marginBottom: 0 }} /> : null}>
        {buttonText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <Box className={classes.paper}>
          <Typography variant="h6" component="h2">
            {overlayTitle}
          </Typography>
          <Typography variant="body2" component="p">
            {overlayContent}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default OverlayButton;
