import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#ffffff", // White background
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Shadow
    color: "#333333", // Text color
    margin: "10px", // Padding (adjust as needed)
    "&:hover": {
      backgroundColor: "#f0f0f0", // Hover background color
    },
  },
}));

const GatsbyButton = ({ to, href, children, icon, iwidth, iheight }) => {
  const classes = useStyles();

  if (to) {
    // If it's an internal link, use Gatsby's Link component
    return (
      <Button
        variant="contained"
        className={classes.button}
        component={Link}
        to={to}
        startIcon={icon ? <img src={icon} alt="icon" style={{ width: iwidth, height: iheight, marginBottom: 0 }} /> : null}
        style={{ width: "100%" }}
      >
        {children}
      </Button>
    );
  } else if (href) {
    // If it's an external link, use a regular anchor tag
    return (
      <Button
        variant="contained"
        className={classes.button}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        startIcon={icon ? <img src={icon} alt="icon" style={{ width: iwidth, height: iheight, marginBottom: 0 }} /> : null}
        style={{ width: "100%" }}
      >
        {children}
      </Button>
    );
  } else {
    return null;
  }
};

export default GatsbyButton;
