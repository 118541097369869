import React from "react"
// import { Link } from "gatsby"

import { Container, Grid } from '@material-ui/core';
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import GatsbyButton from "../components/button"
import OverlayButton from '../components/OverlayButton';
// import ImageButton from "../components/ImageButton";
import airbnbIcon from "../images/airbnb.png";
import instaIcon from "../images/insta.png";
import mailIcon from "../images/mail.png";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container style={{ height: '30vh', display: 'flex', alignItems: 'center' }}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
        {/* <ImageButton src={stanza1} iwidth={300} alt="Logo" href="https://airbnb.com/h/marecolli" /> */}
          <GatsbyButton href="https://airbnb.com/h/marecolli" icon={airbnbIcon} iwidth={35} iheight={35}>Check availability</GatsbyButton>
          {/* <GatsbyButton href="https://linktr.ee/marecolli">Visita il nostro LinkTree</GatsbyButton> */}
        </Grid>
        <Grid item xs={12}>
          <GatsbyButton href="https://www.instagram.com/marecolli.casa" icon={instaIcon} iwidth={30} iheight={30}>Follow us</GatsbyButton>
        </Grid>
        <Grid item xs={12}>
          <OverlayButton
          buttonText="Get in touch"
          overlayTitle="Get in touch"
          overlayContent="e-mail: marecollistay@gmail.com"
          icon={mailIcon}
          iwidth={30} iheight={30}
          style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Container>
  </Layout>
)

export default IndexPage
